@import 'antd/dist/reset.css';
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Table_Hover tbody tr {
    cursor: pointer;
}
.Disabled_Black {
    color: black;
}
.Disabled_Black_Button:disabled {
    color: black;
}
.Disabled_Black_Picker .ant-picker-input > input-disabled, .ant-picker-input > input[disabled] {
    color: black;
}
.Rounded_Card {
    border-radius: 1rem;
    margin-bottom: 1rem;
}
.Rounded_Input {
    border-radius: 0.5rem;
}
.Rounded_Select .ant-select .ant-select-selector {
    border-radius: 0.5rem;
}
.ant-menu-sub.ant-menu-inline >.ant-menu-item {
    padding-right: 0;
}
.ant-menu-sub.ant-menu-inline >.ant-menu-submenu >.ant-menu-submenu-title {
    padding-right: 0;
}
.Button {
    width: 100%;
    border-radius: 0.5rem;
}
.Table_Button {
    border-radius: 0.5rem;
}
.Table_Button15 {
    border-radius: 0.5rem;
    width: 15%;
}
.Table_Button20 {
    border-radius: 0.5rem;
    width: 20%;
}
.Table_Button30 {
    border-radius: 0.5rem;
    width: 30%;
}
.Table_Button40 {
    border-radius: 0.5rem;
    width: 40%;
}
.Table_Button50 {
    border-radius: 0.5rem;
    width: 50%;
}
.Width100 {
    width: 100%;
}
.Width20 {
    width: 20%;
}
.Font08 {
    font-size: 0.7rem;
    padding: 1px;
}
.ant-divider-horizontal.ant-divider-with-text {
    margin: 0;
}
.ant-picker-suffix {
    color: white;
}
.ant-space-vertical {
    vertical-align: top;
    width: 100%;
    padding: 0;
}
.Preview {
    width: 50px;
    height: 50px;
}
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
    -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
    -webkit-transition-delay: 9999s;
}
.Footer {
    text-align: center;
    height: 54px;
}
.ant-layout-sider-trigger {
    background: #dcdcdc;
    min-height: 54px;
}
:where(.css-dev-only-do-not-override-1ij74fp).ant-layout .ant-layout-sider-light .ant-layout-sider-trigger {
    background: #dcdcdc;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: black;
}
.Scroll {
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    transform: rotate(180deg);
    flex-direction: row-reverse;
}
.Scroll::-webkit-scrollbar {
    width: 10px;
}
.Scroll::-webkit-scrollbar-track-piece {
    background: white;
    border: #C1C1C1;
    border-radius: 0.5rem;
}
.Scroll::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background: #C1C1C1;
}
.Scroll::-webkit-scrollbar-button:end:increment {
    height: 50%;
    display: block;
    background: transparent;
}
.ScrollQC .ant-table-content::-webkit-scrollbar {
    height: 15px;
}
.ScrollQC .ant-table-content::-webkit-scrollbar-track-piece {
    background: white;
    border: #C1C1C1;
    border-radius: 0.5rem;
}
.ScrollQC .ant-table-content::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background: #C1C1C1;
}
.ScrollQC .ant-table-content::-webkit-scrollbar-button:end:increment {
    height: 50%;
    display: block;
    background: transparent;
}

:where(.css-dev-only-do-not-override-1ij74fp).ant-descriptions.ant-descriptions-bordered .ant-descriptions-item-label {
    background-color: #e1e3e6;
}
.HighLight {
    background-color: #F0FFFF;
    font-weight: bold;
}
.ant-table-tbody > tr.HighLight:hover > td {
    background-color: #F0FFFF;
}